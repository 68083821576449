import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Actions are the second half of a Rule execution. If a triggering Event's Rule(s) Condition evaluates as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`,
the Rule's Actions are executed. Actions may perform work on data within Firstparty (the Event, Session, or Profile),
send a notification, or perform custom operations through Javascript you may define.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      